import React from 'react'
import {Link} from 'gatsby'


const BlogPagination = (props) => {
    const { tags, slug } = props;
    return (
      <div className="col-lg-12 col-md-12">
          {/* Pagination */}
          <div className="pagination-area text-center">
              {/* <Link to="#" className="prev page-numbers">
                  <i className='bx bx-chevrons-left'></i>
              </Link>
              <span className="page-numbers current" aria-current="page">1</span>
              <Link to="#" className="page-numbers">
                  2
              </Link>
              <Link to="#" className="page-numbers">
                  3
              </Link>
              <Link to="#" className="page-numbers">
                  4
              </Link>
              <Link to="#" className="next page-numbers">
                  <i className='bx bx-chevrons-right'></i>
              </Link> */}
          </div>
      </div>
    )
}

export default BlogPagination
