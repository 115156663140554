import React from 'react'
import {Link} from 'gatsby'
import BlogPagination from './BlogPagination'
import { author } from '../../data/page-data/authorData';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-duotone-svg-icons";

const buildBlogList = articles => (
    articles.slice(0, 6).map((article, index) => (
        <div className="col-lg-4 col-md-6" key={index}>
          <div className="single-blog-post ">
              <div className="post-image">
                  <Link to={article.node.frontmatter.slug}>
                      <img src={article.node.frontmatter.thumbnail.publicURL} alt="blog" />
                  </Link>
              </div>

              <div className="post-content">
                  <ul className="post-meta d-flex justify-content-between align-items-center">
                      <li>
                          <div className="post-author d-flex align-items-center">
                              <img src={author.thumbnail} className="rounded-circle" alt="blog" />
                              <span>{author.name}</span>
                          </div>
                      </li>
                      <li>
                      <FontAwesomeIcon icon={faCalendar} style={{width: '15px', height: '15px', marginRight: '5px' }}/>
                           {article.node.frontmatter.date}
                      </li>
                  </ul>
                  <h3>
                      <Link to={article.node.frontmatter.slug}>
                        {article.node.frontmatter.title}
                      </Link>
                  </h3>
              </div>
          </div>
      </div>
    ))
);

const BlogCard = (props) => {
    const { articles } = props;
    return (
        <div className="blog-area ptb-100">
            <div className="container">
                <div className="row">
                    {buildBlogList(articles)}
                    <BlogPagination />
                </div>
            </div>
        </div>
    )
}

export default BlogCard
