import React from 'react'
import {Link} from 'gatsby'
import comet from '../../assets/images/comet.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-duotone-svg-icons";

const PageBanner = ({pageTitle, homePageUrl, homePageText, activePageText}) => {
    return (
        <div className="page-title-area">
            <div className="container">
                <div className="page-title-content">
                    <h2>{pageTitle}</h2>
                    <ul>
                        <li>
                            <Link to={homePageUrl}>
                                {homePageText}
                            </Link>
                        </li>
                        <FontAwesomeIcon icon={faChevronRight} style={{marginRight: '10px', fontSize: '12px', height: '14px' }}/>
                        <li>{activePageText}</li>
                    </ul>
                </div>
            </div>
            <div className="shape-img2">
                <img src={comet} height={250} width={250 }alt="comet" />
            </div>
        </div>
    )
}

export default PageBanner;
